import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { Entry } from "~/common";
import { StockTransactionHttp } from "~/common/entry";

import { StockRepository } from "./StockRepository";
import { EntryHttpClients } from "../../../clients";
import { RepositoryFromHttp } from "../../common";

type InvalidateParentsFn = (
	params: Parameters<StockTransactionHttp["findAndCount"]>[0] & {
		transactionId?: number;
	},
) => Promise<unknown>;

const { routes } =
	Entry.ENTRY_HTTP_CONFIG.children.stocks.children.transactions;
const httpClient = EntryHttpClients.StockTransactionHttpClient;

export const useStockTransactionRepository = () => {
	const queryClient = useQueryClient();

	const findAndCountQueryKey = (
		params: Parameters<StockTransactionHttp["findAndCount"]>[0],
	) => routes.findAndCount.path(params).split("/");
	const findOneQueryKey = (
		params: Parameters<StockTransactionHttp["findById"]>[0],
	) => routes.findById.path(params).split("/");

	const invalidateParents: InvalidateParentsFn = useCallback(
		({ transactionId, ...params }) =>
			Promise.all([
				// Invalidate the stock and the list (== where closure appears)
				queryClient.invalidateQueries({
					queryKey: StockRepository.findById(params).queryKey,
				}),
				queryClient.invalidateQueries({
					queryKey: findAndCountQueryKey(params),
				}),
				...(transactionId
					? [
							queryClient.invalidateQueries({
								queryKey: findOneQueryKey({
									...params,
									transactionId,
								}),
							}),
						]
					: []),
			]),
		[queryClient],
	);

	return useMemo(
		() =>
			({
				findAndCount: (params, query) => ({
					queryFn: () => httpClient.findAndCount(params, query),
					queryKey: findAndCountQueryKey(params),
				}),
				findById: params => ({
					queryFn: () => httpClient.findById(params),
					queryKey: findOneQueryKey(params),
				}),

				create: {
					mutationFn: ([params, body]) =>
						httpClient.create(params, body),
					onSuccess: (_, [routeParams]) =>
						invalidateParents(routeParams),
				},
				delete: {
					mutationFn: ([params]) => httpClient.delete(params),
					onSuccess: (_, [routeParams]) =>
						invalidateParents(routeParams),
				},
				update: {
					mutationFn: ([params, body]) =>
						httpClient.update(params, body),
					onSuccess: (_, [routeParams]) =>
						invalidateParents(routeParams),
				},
			}) as const satisfies RepositoryFromHttp<Entry.StockTransactionHttp>,
		[invalidateParents],
	);
};

export type StockTransactionRepository = ReturnType<
	typeof useStockTransactionRepository
>;
