import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Entry } from "~/common";

import { EntryHttpClients } from "../../../../clients";
import { entryRepository } from "../../../EntryRepository";
import { RepositoryFromHttp } from "../../../common";

const { routes } = Entry.ENTRY_HTTP_CONFIG.children.pillar3.a;
const httpClient = EntryHttpClients.Pillar3AHttpClient;

/** Repository options of 'entry/pillar3/a/' HTTP client to use with 'react-query' */
export function usePillar3ARepository() {
	const queryClient = useQueryClient();
	const invalidateClient = useCallback(
		(params: Parameters<typeof entryRepository.findById>[0]) =>
			queryClient.invalidateQueries(
				// For the 1st-level models, invalidate the entry, so the navigation bar also updates
				entryRepository.findById(params),
			),
		[queryClient],
	);

	return {
		findAndCount: (params, query) => ({
			queryFn: () => httpClient.findAndCount(params, query),
			queryKey: routes.findAndCount.path(params).split("/"),
		}),
		findById: params => ({
			queryFn: () => httpClient.findById(params),
			queryKey: routes.findById.path(params).split("/"),
		}),

		create: {
			mutationFn: ([params, body]) => httpClient.create(params, body),
			onSuccess: (_, [params]) => invalidateClient(params),
		},
		delete: {
			mutationFn: ([params]) => httpClient.delete(params),
			onSuccess: (_, [params]) => invalidateClient(params),
		},
		update: {
			mutationFn: ([params, body]) => httpClient.update(params, body),
			onSuccess: (_, [params]) => invalidateClient(params),
		},
	} as const satisfies RepositoryFromHttp<Entry.Pillar3.A.Http>;
}
export type Pillar3ARepository = ReturnType<typeof usePillar3ARepository>;
