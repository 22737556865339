import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { Entry } from "~/common";

import { Pillar2AffiliationPlanRepository } from "./Pillar2AffiliationPlanRepository";
import { EntryHttpClients } from "../../../clients";
import { RepositoryFromHttp } from "../../common";

const httpClient = EntryHttpClients.Pillar2AffiliationPlanClosureHttpClient;

/** Repository options of 'entry/pillar2/affiliation/plan/closure' HTTP client to use with 'react-query' */
export function usePillar2AffiliationPlanClosureRepository() {
	const queryClient = useQueryClient();
	const invalidateClient = useCallback(
		(
			params: Parameters<
				typeof Pillar2AffiliationPlanRepository.findAndCount
			>[0],
		) =>
			queryClient.invalidateQueries(
				Pillar2AffiliationPlanRepository.findAndCount(params, {}),
			),
		[queryClient],
	);

	return {
		create: {
			mutationFn: ([params, body]) => httpClient.create(params, body),
			onSuccess: (_, [params]) => invalidateClient(params),
		},
		delete: {
			mutationFn: ([params]) => httpClient.delete(params),
			onSuccess: (_, [params]) => invalidateClient(params),
		},
		update: {
			mutationFn: ([params, body]) => httpClient.update(params, body),
			onSuccess: (_, [params]) => invalidateClient(params),
		},
	} as const satisfies RepositoryFromHttp<Entry.Pillar2AffiliationPlanClosureHttp>;
}
