import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { Entry } from "~/common";

import { StockRepository } from "./StockRepository";
import { EntryHttpClients } from "../../../clients";
import { RepositoryFromHttp } from "../../common";

const httpClient = EntryHttpClients.StockClosureHttpClient;

type InvalidateParentsFn = (
	params: Parameters<typeof StockRepository.findById>[0],
) => Promise<unknown>;

export const useStockClosureRepository = () => {
	const queryClient = useQueryClient();

	const invalidateParents: InvalidateParentsFn = useCallback(
		params =>
			Promise.all([
				// Invalidate the stock and the list (== where closure appears)
				queryClient.invalidateQueries({
					queryKey: StockRepository.findById(params).queryKey,
				}),
				queryClient.invalidateQueries({
					queryKey: StockRepository.findAndCount(params, {}).queryKey,
				}),
			]),
		[queryClient],
	);

	return useMemo(
		() =>
			({
				create: {
					mutationFn: ([params, body]) =>
						httpClient.create(params, body),
					onSuccess: (_, [routeParams]) =>
						invalidateParents(routeParams),
				},
				update: {
					mutationFn: ([params, body]) =>
						httpClient.update(params, body),
					onSuccess: (_, [routeParams]) =>
						invalidateParents(routeParams),
				},
				delete: {
					mutationFn: ([params]) => httpClient.delete(params),
					onSuccess: (_, [routeParams]) =>
						invalidateParents(routeParams),
				},
			}) as const satisfies RepositoryFromHttp<Entry.StockClosureHttp>,
		[invalidateParents],
	);
};
