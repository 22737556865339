import {
	QueryOptions,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { useRouter } from "next/router";

import { authRepository } from "../../services/api/repositories/AuthRepository";
import { ROUTES } from "../../services/routes";

const profileQueryKey: QueryOptions["queryKey"] = ["auth", "profile"];

// "No really" a service, but the idea is here

/** Auth "service" */
export const AuthService = {
	/**
	 * Get the connected user.
	 * This helper expects that the user has already been loaded.
	 * 	{@see useProfile} that can work as a guard
	 *
	 * @throws Error when not connected
	 * @returns The connected user
	 */
	useGetConnectedProfile: () => {
		const { data } = AuthService.useProfile();

		if (data) {
			return data;
		}

		// TODO: better?
		throw new Error("Not connected");
	},
	/**
	 * Logs out a potential user
	 *
	 * @returns React-Query handler
	 */
	useLogout() {
		const client = useQueryClient();
		const router = useRouter();

		return useMutation({
			...authRepository.logout(),
			onSuccess: async () => {
				await client.invalidateQueries({ queryKey: profileQueryKey });
				await router.push(ROUTES.auth.login.url());
			},
		});
	},
	/**
	 * Load the content of the connected user (if any).
	 * Can also works as a guard
	 *
	 * @returns React-Query handler
	 */
	useProfile: () =>
		useQuery({
			...authRepository.getProfile(),
			queryKey: profileQueryKey,
			meta: { skipDefaultErrorHandling: true },
		}),
};
