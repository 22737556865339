import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { Entry } from "~/common";

import { EntryHttpClients } from "../../../clients";
import { RepositoryFromHttp } from "../../common";
import { entryRepository } from "../../index";

const { routes } =
	Entry.ENTRY_HTTP_CONFIG.children.pillar2.children.vestedBenefits;
const httpClient = EntryHttpClients.VestedBenefitsHttpClient;

type Getters = RepositoryFromHttp<
	Pick<Entry.VestedBenefitsHttp, "findAndCount" | "findById">
>;

export const useVestedBenefitsRepository = () => {
	const queryClient = useQueryClient();

	const getters = useMemo(
		() =>
			({
				findAndCount: (params, query) => ({
					queryFn: () => httpClient.findAndCount(params, query),
					queryKey: routes.findAndCount.path(params).split("/"),
				}),
				findById: params => ({
					queryFn: () => httpClient.findById(params),
					queryKey: routes.findById.path(params).split("/"),
				}),
			}) as const satisfies Getters,
		[],
	);

	return useMemo(
		() =>
			({
				...getters,

				create: {
					mutationFn: ([params, body]) =>
						httpClient.create(params, body),
					onSuccess: (_, [routeParams]) =>
						queryClient.invalidateQueries(
							// For the 1st-level models, invalidate the entry, so the navigation bar also updates
							entryRepository.findById(routeParams),
						),
				},
				delete: {
					mutationFn: ([params]) => httpClient.delete(params),
					onSuccess: (_, [routeParams]) =>
						// For the 1st-level models, invalidate the entry, so the navigation bar also updates
						entryRepository.findById(routeParams),
				},
				update: {
					mutationFn: ([params, body]) =>
						httpClient.update(params, body),
					onSuccess: (_, [routeParams]) =>
						queryClient.invalidateQueries({
							queryKey: getters.findById(routeParams).queryKey,
						}),
				},

				invalidate: params =>
					Promise.all([
						queryClient.invalidateQueries({
							queryKey: getters.findById(params).queryKey,
						}),
						queryClient.invalidateQueries({
							queryKey: getters.findAndCount(params, {}).queryKey,
						}),
					]).then(() => void 0),
			}) as const satisfies RepositoryFromHttp<Entry.VestedBenefitsHttp> & {
				invalidate: (
					params: Parameters<(typeof getters)["findById"]>[0],
				) => Promise<void>;
			},
		[getters, queryClient],
	);
};
