import { useMemo } from "react";
import { Entry } from "~/common";

import { useVestedBenefitsRepository } from "./VestedBenefitsRepository";
import { EntryHttpClients } from "../../../clients";
import { RepositoryFromHttp } from "../../common";

const httpClient = EntryHttpClients.VestedBenefitsClosureHttpClient;

export const useVestedBenefitsClosureRepository = () => {
	const { invalidate: invalidateParents } = useVestedBenefitsRepository();

	return useMemo(
		() =>
			({
				create: {
					mutationFn: ([params, body]) =>
						httpClient.create(params, body),
					onSuccess: (_, [routeParams]) =>
						invalidateParents(routeParams),
				},
				update: {
					mutationFn: ([params, body]) =>
						httpClient.update(params, body),
					onSuccess: (_, [routeParams]) =>
						invalidateParents(routeParams),
				},
				delete: {
					mutationFn: ([params]) => httpClient.delete(params),
					onSuccess: (_, [routeParams]) =>
						invalidateParents(routeParams),
				},
			}) as const satisfies RepositoryFromHttp<Entry.VestedBenefitClosureHttp>,
		[invalidateParents],
	);
};
